<template>
  <v-container>
      <v-row  class="mt-10">
         <v-col cols="12" sm="12" md="12">
             <v-row justify="center" align="center">
                 <span class="not-found">404</span>
             </v-row>
         </v-col>

         <v-col cols="12" sm="12" md="12">
             <v-row justify="center" align="center">
                    <h1>Página no encontrada 😔</h1>
             </v-row>
         </v-col>
      </v-row>
  </v-container>
</template>

<script>
export default {

}
</script>

<style>
.not-found {
    font-size: 100px;
    color: #00cdbc;
}
</style>